.toolbar {
  z-index: 100;
  background-color: var(--toolbar-background-color);
  color: var(--toolbar-color);
  display: flex;
  &__item {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      @media (hover: hover) {
        background-color: var(--toolbar-background-color-hover);
        color: var(--toolbar-color-hover);
      }
    }
    &--hover {
      background-color: var(--toolbar-background-color-hover);
      color: var(--toolbar-color-hover);
    }
    &--title {
      padding-left: 10px;
      font-weight: 400;
      font-size: 24px;
    }
    &__text {
      &--with-icon {
        margin-right: 5px;
      }
      &--icon {
        padding: 0px;
        margin: 0px;
        text-align: right;
        &--center {
          text-align: center;
        }
      }
    }
  }
  &__spacer {
    width: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    top: 0px;
    bottom: 0px;
    background-color: var(--toolbar-spacer-color);
  }
  &__show-more-container {
    position: absolute;
    display: block;
    z-index: 200;
    right: 0px;
    top: 42px;
    &--show-more {
      box-shadow: 0px 2px 5px lightgrey;
    }
    &__item {
      display: block;
      white-space: nowrap;
      background-color: red;
    }
  }
}

.dropdown {
  margin-top: 5px;
}

@media only screen and (max-width: 599px) {
  .toolbar {
    height: 44px;
    &__item {
      padding: 5px 10px;
      line-height: 34px;
    }
    .dropdown {
      margin-right: -10px;
      margin-left: -10px;
    }
  }
  .sidenav-container__top-margin {
    top: 44px !important;
  }
}

@media only screen and (min-width: 600px) {
  .toolbar {
    height: 52px;
    &__item {
      padding: 5px 20px;
      line-height: 42px;
    }
    .dropdown {
      margin-right: -20px;
      margin-left: -20px;
    }
  }

  .sidenav-container__top-margin {
    top: 52px !important;
  }
}
