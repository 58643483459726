@import "~@fortawesome/fontawesome-free/css/all.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.nowrap {
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

button.red {
  background-color: rgba(250, 0, 0, 0.8) !important;
  color: white !important;
}

button.green {
  background-color: rgba(0, 180, 0, 0.8) !important;
  color: white !important;
}

.margin-to-right {
  margin-right: var(--button-to-button-margin);
}

button.margin {
  margin-right: var(--button-to-button-margin);
  margin-bottom: var(--button-to-button-margin);
}
button.margin:last-child {
  margin-right: 0px;
}

.row-container {
  margin-left: 0rem;
  margin-right: 0rem;
  line-height: 20px;
}

.item-in-row-no-col {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.item-in-row-no-col:last-of-type {
  padding-right: 0rem;
}

a {
  padding: var(--link-padding);
  border-radius: var(--link-border-radius);
  text-decoration: none;
  background-color: var(--link-background-color);
  color: var(--link-color);
}

a.visited {
  color: var(--link-color--visited);
}

@media (hover: hover) {
  a:hover {
    //text-decoration: underline;
    background-color: var(--link-background-color--hover);
    color: var(--link-color--hover);
  }
}

textarea {
  max-height: 500px;
  min-height: 100px;
}

textarea.small {
  min-height: 50px;
}
