@import "./colors.scss";
@import "~@angular/material/theming";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);

mat-form-field.full-width {
  display: block !important;
  max-width: 400px;
}

.mat-form-field-infix {
  padding: 5px !important;
}

.mat-form-field-label-wrapper {
  padding-top: 0px;
  padding-bottom: 0.75em;
}

mat-form-field:not(.mat-form-field-should-float) .mat-form-field-label {
  top: 1.2em !important;
}

.mat-content {
  justify-content: space-between;
}

.mat-content > mat-panel-title,
.mat-content > mat-panel-description {
  flex: 0 0 auto;
}

.mat-stroked-button {
  margin-top: 3px !important;
  line-height: 30px !important;
}

.row.actions-only .mat-stroked-button {
  margin-top: 0px !important;
  line-height: 20px !important;
}

.row.actions-only .mat-stroked-button.icon {
  padding-left: 2px !important;
  padding-right: 2px !important;
  min-width: initial !important;
  margin-right: 2px;
  .mat-icon {
    margin-top: 2px;
    height: 14px;
  }
}

// To show snackbar
.cdk-overlay-container {
  z-index: 1000000;
}
